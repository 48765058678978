import { useStaticQuery, graphql } from 'gatsby';
import { encodeQueryParams, StringParam } from 'use-query-params';
import { stringify } from 'query-string';
import useCurrentPath from '@helpers/hooks/useCurrentPath';
import {
  URL_RESULTS_PATH,
  SORT_UPCOMING,
  SORT_POPULAR,
  SORT_RECENT,
  COST_SPONSORED,
} from '@modules/CareerDevelopment/Courses/helpers/constants';
import getCourseCardsProps from '@helpers/methods/modifyContents/getCourseCardsProps';
import useFlexSuggestionIndex from '@helpers/hooks/useFlexSuggestionIndex';

const GET_CATEGORIES = graphql`
  query {
    allStrapiCoursesCategories(sort: { fields: sort, order: ASC }) {
      nodes {
        name
      }
    }

    upcomingScheduledCourses: allStrapiCourses(
      sort: { fields: start_date, order: ASC }
      filter: {
        content_status: { value: { eq: "2" } }
        start_date: { ne: "null" }
      }
    ) {
      nodes {
        strapiId
        created_at(formatString: "MMM DD, YYYY")
        published_date
        published_at
        content
        courses_category {
          name
        }
        slug
        title
        registration_deadline
        start_date
        end_date
        level {
          label
          value
        }
        cost {
          label
          type
          sponsor_name
          sponsorLogo: sponsor_logo {
            localFile {
              extension
              publicURL
            }
          }
        }
        duration

        creator_id
        author {
          username
        }
        created_by {
          username
        }

        location {
          province {
            name
          }
          type
        }
      }
    }
    allCourses: allStrapiCourses(
      sort: { fields: registration_deadline, order: ASC }
      filter: { content_status: { value: { eq: "2" } } }
    ) {
      nodes {
        strapiId
        created_at(formatString: "MMM DD, YYYY")
        published_date
        published_at
        content
        courses_category {
          name
        }
        slug
        title
        registration_deadline
        start_date
        end_date
        level {
          label
          value
        }
        cost {
          label
          type
          sponsor_name
          sponsorLogo: sponsor_logo {
            localFile {
              extension
              publicURL
            }
          }
        }
        duration

        creator_id
        author {
          username
        }
        created_by {
          username
        }

        location {
          province {
            name
          }
          type
        }
      }
    }

    allTitleSuggestions: allStrapiCourses(
      sort: { fields: created_at, order: DESC }
    ) {
      nodes {
        title
      }
    }

    flexSearchOptions: localSearchCourses {
      index
      store
    }

    flexSuggestionOptions: localSearchCoursesSuggestions {
      index
    }
  }
`;

const getCategoryNames = (categories = []) =>
  categories.map((category) => category?.name);

const sanitizeArray = (array = []) => {
  return array ?? [];
};

const encodeViewAllURL = (sort = '', rootPath = '') => {
  const encodedCategory = encodeQueryParams(
    { sort: StringParam },
    { sort: sort }
  );
  const urlPath = `${rootPath}${URL_RESULTS_PATH}`;
  const urlQueryParams = `?${stringify(encodedCategory)}`;

  return `${urlPath}${urlQueryParams}`;
};
const encodeViewSponsoredURL = (cost = '', sort = '', rootPath = '') => {
  const encodedCategory = encodeQueryParams(
    { sort: StringParam },
    { sort: sort }
  );
  const encodeCost = encodeQueryParams({ cost: StringParam }, { cost: cost });
  const urlPath = `${rootPath}${URL_RESULTS_PATH}`;
  const urlQueryParams = `?${stringify(encodeCost)}&${stringify(
    encodedCategory
  )}`;

  return `${urlPath}${urlQueryParams}`;
};

const encodeCategoryURL = (category = '', rootPath = '') => {
  const encodedCategory = encodeQueryParams(
    { category: StringParam },
    { category: category }
  );
  const urlPath = `${rootPath}${URL_RESULTS_PATH}`;
  const urlQueryParams = `?${stringify(encodedCategory)}`;

  return `${urlPath}${urlQueryParams}`;
};

const generateCategoryPaths = (categories = [], rootPath = '') =>
  categories.map((category) => {
    return {
      name: category,
      path: encodeCategoryURL(category, rootPath),
    };
  });

const Fetcher = ({ children }) => {
  const {
    allStrapiCoursesCategories,
    upcomingScheduledCourses,
    allCourses,
    flexSearchOptions,
    flexSuggestionOptions,
    allTitleSuggestions,
  } = useStaticQuery(GET_CATEGORIES);

  const upcomingScheduledCoursesNodes = upcomingScheduledCourses?.nodes;
  const upcomingCoursesOnly = true;
  const upcomingScheduledCourseCardItems = getCourseCardsProps(
    upcomingScheduledCoursesNodes,
    upcomingCoursesOnly
  );

  const currentUrlPath = useCurrentPath();

  const categoryNames = getCategoryNames(allStrapiCoursesCategories.nodes);
  const sanitizedCategoryNames = sanitizeArray(categoryNames);
  const categoryPaths = generateCategoryPaths(
    sanitizedCategoryNames,
    currentUrlPath
  );

  const allCoursesNodes = allCourses?.nodes;
  const allCourseCardItems = getCourseCardsProps(allCoursesNodes);

  const sponsoredCardItems = allCourseCardItems.filter((allCourseCardItem) => {
    const sponsoredItem = allCourseCardItem?.cost?.label;
    return sponsoredItem === 'Sponsored';
  });
  const firstTwoItemSponsoredCards = sponsoredCardItems.slice(0, 2);

  const viewAllRedirection = {
    upcoming: encodeViewAllURL(SORT_UPCOMING),
    popular: encodeViewAllURL(SORT_POPULAR),
    sponsored: encodeViewSponsoredURL(COST_SPONSORED, SORT_RECENT),
  };

  const suggestedWordsIndex = useFlexSuggestionIndex(
    flexSuggestionOptions?.index
  );

  const suggestedTitlesIndex = allTitleSuggestions.nodes.map(
    ({ title }) => title
  );

  const searchSuggestions = [...suggestedWordsIndex, ...suggestedTitlesIndex];

  return children({
    firstTwoItemSponsoredCards,
    upcomingScheduledCourseCardItems,
    categoryPaths,
    viewAllRedirection,
    flexSearchOptions,
    searchSuggestions,
  });
};

export default Fetcher;
