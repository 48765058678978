import React from 'react';
import SEO from '@components/SEO';
import Layout from '@components/Layout/LayoutTemplates/CareerDevelopmentLayout';
import BrowseCategory from '@components/BrowseCategory';
import MainPageSearch from '@components/SearchWithCTA/MainPageSearch';
import SectionHeader from '@components/SectionHeader';
import CourseCard from '@components/Cards/CourseCard';
import ArrowButton from '@components/ArrowButton';
import Section from '@components/Section';
import useCurrentPath from '@helpers/hooks/useCurrentPath';
import { navigate } from 'gatsby';
import { stringify } from 'query-string';
import { encodeQueryParams, StringParam, ArrayParam } from 'use-query-params';
import { URL_RESULTS_PATH } from '../helpers/constants';

import {
  SEO_TITLE,
  SEARCH_HEADER,
  SEARCH_SUB_HEADER,
  SEARCH_PLACEHOLDER,
} from './helpers/constants';

const CareerAdvice = ({ data }) => {
  const {
    firstTwoItemSponsoredCards,
    categoryPaths,
    upcomingScheduledCourseCardItems,
    viewAllRedirection,
    flexSearchOptions,
    searchSuggestions,
  } = data;

  const displayedUpcomingScheduledCourseCardItems = upcomingScheduledCourseCardItems.slice(
    0,
    2
  );

  const currentUrlPath = useCurrentPath();

  const handleSearchQuery = (response) => {
    const { query, results } = response;
    const encodedQuery = encodeQueryParams(
      { query: StringParam, search: ArrayParam },
      { query, search: results }
    );
    const urlPath = `${currentUrlPath}${URL_RESULTS_PATH}`;
    const urlQueryParams = `?${stringify(encodedQuery)}`;
    navigate(`${urlPath}${urlQueryParams}`);
  };

  const getSectionVisibilityByArrayLength = (array) => {
    const isNotArrayType = !Array.isArray(array);
    if (isNotArrayType) return false;

    const hasNoArrayContent = array.length <= 0;
    if (hasNoArrayContent) return false;

    return true;
  };

  return (
    <Layout isReleased={true} className={'courses-container'}>
      <SEO title={SEO_TITLE} />
      <MainPageSearch
        header={SEARCH_HEADER}
        subHeader={SEARCH_SUB_HEADER}
        searchPlaceholder={SEARCH_PLACEHOLDER}
        onQuery={handleSearchQuery}
        flexSearchOptions={flexSearchOptions}
        searchSuggestions={searchSuggestions}
      />

      <BrowseCategory categories={categoryPaths} />

      <Section
        className={'sponsored-courses-section'}
        isVisible={getSectionVisibilityByArrayLength(
          firstTwoItemSponsoredCards
        )}
      >
        <SectionHeader label={'Sponsored courses'} />
        <div className={'course-card-group'}>
          {firstTwoItemSponsoredCards.map((firstTwoItemSponsoredCard, idx) => (
            <CourseCard {...firstTwoItemSponsoredCard} key={idx} />
          ))}
        </div>
        <ArrowButton
          link={viewAllRedirection?.sponsored}
          label={'View all sponsored'}
        />
      </Section>

      <Section
        className={'upcoming-courses-section'}
        isVisible={getSectionVisibilityByArrayLength(
          displayedUpcomingScheduledCourseCardItems
        )}
      >
        <SectionHeader label={'Upcoming scheduled courses'} />
        <div className={'course-card-group'}>
          {displayedUpcomingScheduledCourseCardItems.map((cardItem, idx) => (
              <CourseCard {...cardItem} key={idx} />
            ))}
        </div>
        <ArrowButton
          link={viewAllRedirection?.upcoming}
          label={'View all upcoming'}
        />
      </Section>

      <Section className={'most-popular-courses-section'} isVisible={false}>
        <SectionHeader label={'Most popular courses'} />
        <div className={'course-card-group'}>
          {displayedUpcomingScheduledCourseCardItems.map((cardItem, idx) => (
            <CourseCard {...cardItem} key={idx} />
          ))}
        </div>
        <ArrowButton
          link={viewAllRedirection?.popular}
          label={'View all popular'}
        />
      </Section>
    </Layout>
  );
};

export default CareerAdvice;
